/**
 * This code was generated by Builder.io
 */
.admin-dashboard {
  min-height: 100vh;
  width: 100%;
}

.logo {
  padding: 24px;
  text-align: center;
}

.logo img {
  max-width: 100%;
  height: auto;
}

.header-section {
  padding: 24px;
}

.admin-badge {
  float: right;
  padding: 4px 8px;
  background: #eee;
  border-radius: 4px;
}

.stats-card {
  text-align: center;
}

.stats-divider {
  margin: 24px 0;
  border-top: 1px solid #f0f0f0;
}

.stat-number {
  color: #0478c2 !important;
}

.review-link {
  color: #0478c2;
  cursor: pointer;
  display: block;
}

.dashboard-content {
  padding: 0 64px;
}

.announcements-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.announcement-image {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.ant-layout-footer {
  background: #fff;
  padding: 24px 48px;
}
.pending-incentives-box {
  background-color: #0478c2;
  padding: 26px;
  border-radius: 8px;
  margin-bottom: 12px;
  text-align: center;
  height: 12rem;
}

.pending-incentives-box h1,
.pending-incentives-box span {
  margin-bottom: 4px;
  color: #ffff;
}

.ant-layout {
  background: #fff;
}

.ant-layout-sider {
  background: #fff;
}

.ant-layout-content {
  width: 100%;
  max-width: 100%;
}
