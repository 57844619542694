.headerWrapper {
  position: relative;
  margin-bottom: 350px;
}

.headerSection {
  background: linear-gradient(to right, #f8f9fc, #eef1f8);
  position: relative;
  padding: 1rem;
  overflow: hidden;
  height: auto;
  min-height: 300px;
}

.cardsRow {
  position: absolute;
  bottom: -300px;
  left: 1rem;
  right: 1rem;
  z-index: 1;
  margin: 0px 35px;
}

@media (max-width: 768px) {
  .headerWrapper {
    margin-bottom: 600px;
  }

  .headerSection {
    min-height: 400px;
  }

  .cardsRow {
    bottom: -550px;
    margin: 0px 5px !important;
  }
}
