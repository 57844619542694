/**
 * This code was generated by Builder.io
 */
.admin-dashboard {
  min-height: 100vh;
  width: 100%;
}

.logo {
  padding: 24px;
  text-align: center;
}

.logo img {
  max-width: 100%;
  height: auto;
}

.header-section {
  padding: 24px;
}

.admin-badge {
  float: right;
  padding: 4px 8px;
  background: #eee;
  border-radius: 4px;
}

.stats-card {
  text-align: center;
}

.stats-divider {
  margin: 24px 0;
  border-top: 1px solid #f0f0f0;
}

.stat-number {
  color: #0478c2 !important;
}

.review-link {
  color: #0478c2;
  cursor: pointer;
  display: block;
}

.dashboard-content {
  padding: 20px 64px;
}

.announcements-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.announcement-image {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.ant-layout-footer {
  background: #fff;
  padding: 24px 48px;
}
.pending-incentives-box {
  background-color: #0478c2;
  padding: 26px;
  border-radius: 8px;
  margin-bottom: 12px;
  text-align: center;
}

.pending-incentives-box h1,
.pending-incentives-box span {
  margin-bottom: 4px;
  color: #ffff;
}

.ant-layout {
  background: #fff;
}

.ant-layout-sider {
  background: #fff;
}

.ant-layout-content {
  width: 100%;
  max-width: 100%;
}

.action-buttons {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.action-buttons .anticon {
  margin-left: 16px;
}

@media screen and (max-width: 576px) {
  .action-buttons {
    width: 100%;
    justify-content: space-between;
    margin-top: 16px;
  }
  
  .action-buttons .anticon {
    width: 48%;
    padding: 8px;
    background-color: #f5f5f5;
    border-radius: 4px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0;
  }
}
